import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_daily_variance = _resolveComponent("daily-variance")!
  const _component_rolling_variance = _resolveComponent("rolling-variance")!
  const _component_monthly = _resolveComponent("monthly")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Variance Report" }, {
    default: _withCtx(() => [
      _createVNode(_component_daily_variance),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_rolling_variance),
      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_monthly)
    ]),
    _: 1
  }))
}