import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "gutter-box text-left" }
const _hoisted_2 = { class: "gutter-box text-left" }
const _hoisted_3 = { class: "gutter-box text-left" }
const _hoisted_4 = { class: "gutter-box text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, null, {
    default: _withCtx(() => [
      _cache[17] || (_cache[17] = _createElementVNode("h3", { class: "text-left" }, "Filter Option 5 or 30 Days Variance:", -1)),
      _createVNode(_component_a_row, { gutter: [16,8] }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            class: "gutter-row",
            span: 6
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { for: "Member" }, "Site:", -1)),
                _createVNode(_component_a_select, {
                  value: _ctx.filter.site_id,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.site_id) = $event)),
                  id: "site_id",
                  style: {"width":"100%"},
                  onChange: _ctx.handleChange,
                  "show-search": "",
                  filterOption: _ctx.filterOption
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_sites, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.site_name), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "onChange", "filterOption"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            class: "gutter-row",
            span: 6
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _cache[8] || (_cache[8] = _createElementVNode("label", { for: "Member" }, "Site Product:", -1)),
                _createVNode(_component_a_select, {
                  value: _ctx.filter.site_product_id,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.site_product_id) = $event)),
                  id: "site_product_id",
                  style: {"width":"100%"},
                  "show-search": "",
                  filterOption: _ctx.filterOption
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select_option, { value: "all" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("All")
                      ])),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_site_products, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.product.product_name) + " (" + _toDisplayString(item.tank.name) + ") ", 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "filterOption"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { gutter: [16,8] }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            class: "gutter-row",
            span: 6
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _cache[9] || (_cache[9] = _createElementVNode("label", { for: "from" }, "Period:", -1)),
                _cache[10] || (_cache[10] = _createTextVNode()),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_a_select, {
                  value: _ctx.filter.period,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.period) = $event)),
                  id: "period",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.period, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            class: "gutter-row",
            span: 6
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _cache[12] || (_cache[12] = _createElementVNode("label", { for: "from" }, "Date:", -1)),
                _cache[13] || (_cache[13] = _createTextVNode()),
                _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_a_date_picker, {
                  value: _ctx.filter.from,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.from) = $event)),
                  "disabled-date": _ctx.disabledDate,
                  style: {"width":"100%"}
                }, null, 8, ["value", "disabled-date"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"padding-top":"10px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleExportToExcel())),
                    disabled: _ctx.isDisabledExcel
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode(" Export to Excel ")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleExportToPDF())),
                    disabled: _ctx.isDisabledPdf
                  }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode(" Export to PDF ")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}